import _ from "underscore";
import Session from "Session";
import moment from "moment";
import "moment-timezone";

function DateTimeParseService() {
    const SHORT_FORMAT = "M/D/YY";
    const TIME_FORMAT = "h:mm a";
    const NO_DATE_PLACEHOLDER = "--";

    function isDateValid(timestamp) {
        timestamp = timestampCleanup(timestamp);
        return timestamp && moment(timestamp).isValid();
    }

    function getTimezone(timestamp) {
        if (!_.isString(timestamp)) {
            return 0;
        }
        const timezone = timestamp.slice(-6);
        if (!timezone.match(/^(\+|\-)/)) {
            return 0;
        }

        return timezone;
    }

    function getTimezoneName(timestamp) {
        if (!_.isString(timestamp)) {
            return;
        }
        const timezoneName = timestamp.match(/(\[.+?\])|(\[.+?)$/);
        if (!timezoneName) {
            return;
        }

        return timezoneName[0].replace(/[\[\]]/g, "");
    }

    function timestampCleanup(timestamp) {
        if (_.isString(timestamp)) {
            const index = timestamp.indexOf("[");
            if (index > -1) {
                timestamp = timestamp.substring(0, index);
            }
            const { length } = timestamp;
            if (timestamp[length - 1] === "Z") {
                timestamp = timestamp.slice(0, length - 1);
            }
        }

        return timestamp;
    }

    return {
        parse(timestamp, timezoneName, withoutConversion) {
            const clientTimezoneName = Session.getInstance()
                .getClient()
                .getTimezone();
            if (!timestamp && !isDateValid(timestamp)) {
                return NO_DATE_PLACEHOLDER;
            }

            let timezone;
            let date = "";
            let time = "";
            timezoneName =
                timezoneName ||
                getTimezoneName(timestamp) ||
                clientTimezoneName;
            timestamp = timestampCleanup(timestamp);
            timezone = getTimezone(timestamp);
            if (withoutConversion) {
                date = `<span>${moment(timestamp).format(SHORT_FORMAT)}</span>`;
            } else {
                let formattedDateTime = moment.utc(timestamp);
                if (timezoneName) {
                    formattedDateTime = formattedDateTime.tz(timezoneName);
                } else if (timezone) {
                    formattedDateTime = formattedDateTime.zone(timezone);
                }
                date = `<span>${formattedDateTime.format(SHORT_FORMAT)}</span>`;
            }
            if (timezoneName) {
                let timeObject = moment.utc(timestamp);
                let formatObject = moment.utc(timestamp).tz(timezoneName);
                if (!withoutConversion) {
                    timeObject = timeObject.tz(timezoneName);
                    formatObject = formatObject.tz(timezoneName);
                }
                time = `<span>${timeObject.format(
                    TIME_FORMAT
                )} ${formatObject.format("z")}</span>`;
            } else if (timezone) {
                time = `<span>${moment
                    .utc(timestamp)
                    .format(`${TIME_FORMAT} [GMT]Z`)}</span>`;
            } else {
                time = `<span>${moment
                    .utc(timestamp)
                    .format(TIME_FORMAT)}</span>`;
            }
            return date + time;
        },
    };
}

export default DateTimeParseService();
