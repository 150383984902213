import _ from "underscore";
import Session from "Session";
import moment from "moment";
import "moment-timezone";
// DEPRECATED - use date-time-service

const queryDateFormat = "YYYY-MM-DD";
const monthYearDateFormat = "MMMM, YYYY";
const dateFormat = "M/D/YY";
const dayMonthFormat = "M/D";
const timeFormat = "h:mm:ss a";
const dateTimeFormat = "M/D/YY h:mm:ss a";
const isoDateTimeFormat = "YYYY-MM-DDTHH:mm:ss.SSS";
const reportDateWithZeroTimeFormat = "YYYY-MM-DDT00:00:00";
const reportDateTimeFormat = "YYYY-MM-DDTHH:mm:00";
const dateTimeLength = 19;
const dateLength = 10;
const timeLength = 5;
let clientTimezoneName;

function DateTime() {
    moment.locale("en", {
        ordinal(number) {
            const b = number % 10;
            const output =
                ~~((number % 100) / 10) === 1
                    ? "th"
                    : b === 1
                    ? "st"
                    : b === 2
                    ? "nd"
                    : b === 3
                    ? "rd"
                    : "th";
            return number + output;
        },
    });

    return {
        formatDateTime(
            timestamp,
            format,
            timezoneName,
            withoutConversion,
            withTimezoneName
        ) {
            clientTimezoneName = Session.getInstance()
                .getClient()
                .getTimezone();
            if (!timestamp) {
                return null;
            }
            let timezone;
            try {
                timezoneName =
                    timezoneName ||
                    getTimezoneName(timestamp) ||
                    clientTimezoneName;
                timestamp = timestampCleanup(timestamp, withoutConversion);
                timezone = getTimezone(timestamp);
            } catch (error) {
                // Do Nothing
            }
            if (!withoutConversion) {
                let formattedDateTime = moment.utc(timestamp);
                if (timezoneName) {
                    formattedDateTime = formattedDateTime.tz(timezoneName);
                } else if (timezone) {
                    formattedDateTime = formattedDateTime.zone(timezone);
                }
                return formattedDateTime.format(
                    format || dateTimeFormat + (withTimezoneName ? " z" : "")
                );
            }
            return (
                moment(timestamp).format(format || dateTimeFormat) +
                (withTimezoneName ? getFormattedTimezone(timezoneName) : "")
            );
        },

        formatTime(timestamp, timezoneName) {
            return (
                this.formatDateTime(timestamp, timeFormat, timezoneName) +
                getFormattedTimezone(timezoneName)
            );
        },

        formatDateTimeWithTimezone(timestamp, timezoneName) {
            return (
                this.formatDateTime(timestamp) +
                getFormattedTimezone(timezoneName)
            );
        },

        formatDate(timestamp, timezoneName) {
            clientTimezoneName = Session.getInstance()
                .getClient()
                .getTimezone();
            timezoneName = timezoneName || clientTimezoneName;
            return formatDate(timestamp, true, timezoneName);
        },

        formatDayMonth(timestamp) {
            clientTimezoneName = Session.getInstance()
                .getClient()
                .getTimezone();
            return moment(timestamp)
                .tz(clientTimezoneName)
                .format(dayMonthFormat);
        },

        formatQueryDate(timestamp) {
            return moment(timestamp).format(queryDateFormat);
        },

        formatReportDate(timestamp) {
            return formatReportDate(timestamp);
        },

        formatReportDateWithZeroTime(timestamp) {
            return moment(timestampCleanup(timestamp)).format(
                reportDateWithZeroTimeFormat
            );
        },

        formatReportDateTime(timestamp, withTimezone, withoutConversion) {
            return this.formatDateTime(
                timestampCleanup(timestamp),
                reportDateTimeFormat,
                null,
                withoutConversion,
                withTimezone
            );
        },

        formatFullDateTime(timestamp) {
            return this.formatDateTime(
                timestampCleanup(timestamp),
                isoDateTimeFormat
            );
        },

        formatReportDateRange(startDate, endDate) {
            const dates = parseDates(startDate, endDate);
            startDate = dates.startDate;
            endDate = dates.endDate;

            return `${formatReportDate(startDate)}/${formatReportDate(
                endDate
            )}`;
        },

        formatReportDateRangeString(startDate, endDate, timezoneName) {
            const dates = parseDates(startDate, endDate);
            startDate = dates.startDate;
            endDate = dates.endDate;

            return `${formatDate(
                startDate,
                true,
                timezoneName,
                true
            )} - ${formatDate(endDate, true, timezoneName, true)}`;
        },

        formatReportTimeRange(startTime, endTime, withoutConversion) {
            const dates = parseDates(startTime, endTime);
            startTime = dates.startDate;
            endTime = dates.endDate;

            return `${this.formatReportDateTime(
                startTime,
                false,
                withoutConversion
            )}/${this.formatReportDateTime(endTime, false, withoutConversion)}`;
        },

        formatReportTimeRangeString(startTime, endTime) {
            const dates = parseDates(startTime, endTime);
            startTime = this.formatDateTime(
                dates.startDate,
                dateTimeFormat,
                null,
                true,
                true
            );
            endTime = this.formatDateTime(
                dates.endDate,
                dateTimeFormat,
                null,
                true,
                true
            );

            return `${startTime} - ${endTime}`;
        },

        formatMonthYearDate(date) {
            return moment(date).format(monthYearDateFormat);
        },

        formatISODateTime(timestamp) {
            return `${moment.utc(timestamp).format(isoDateTimeFormat)}Z`;
        },

        formatPeriodDate(timestamp, period) {
            if (!timestamp || timestamp === "none") {
                return "";
            }

            if (period === "DAY" || period === "DAILY") {
                return this.formatDateTime(
                    timestamp.substring(0, dateTimeLength)
                );
            }

            return formatDate(timestamp.substring(0, dateLength));
        },

        formatPeriodDateRange(timestamps, period) {
            if (!timestamps || timestamps === "none") {
                return "";
            }

            const self = this;
            return timestamps
                .split(/ - |(\]\/)|~/)
                .filter((timestamp) => timestamp && timestamp.length > 3)
                .map((timestamp) => self.formatPeriodDate(timestamp, period))
                .join(" - ");
        },

        formatTimeRange(timestamps) {
            const self = this;
            if (!timestamps) {
                return "";
            }

            return timestamps
                .split(/ - |(\]\/)|~/)
                .filter((timestamp) => timestamp && timestamp.length > 3)
                .map((timestamp) => self.humanizeReportDateTime(timestamp))
                .join(" - ");
        },

        humanizeReportDateTime(timestamp) {
            return this.formatReportDateTime(timestamp);
        },

        timestampCleanup(timestamp) {
            return timestampCleanup(timestamp);
        },

        isDateValid(timestamp) {
            timestamp = timestampCleanup(timestamp);
            return timestamp && moment(timestamp).isValid();
        },

        formatTimeDifference(dateEnd, dateStart) {
            const secondsInMinute = 60;
            const minutesInHour = 60;
            let seconds = moment(dateEnd).diff(dateStart, "seconds");
            let minutes = parseInt(seconds / secondsInMinute);
            let hours = 0;
            seconds -= minutes * secondsInMinute;

            if (minutes >= minutesInHour) {
                hours = parseInt(minutes / minutesInHour);
                minutes -= hours * minutesInHour;
            }

            return `${
                (hours ? `${hours}:` : "") + (minutes < 10 ? "0" : "") + minutes
            }:${seconds < 10 ? "0" : ""}${seconds}`;
        },

        getDateFormat() {
            return dateFormat;
        },

        getMonthYearDateFormat() {
            return monthYearDateFormat;
        },

        getDateTimeFormat() {
            return dateTimeFormat;
        },

        getQueryDateFormat() {
            return queryDateFormat;
        },

        getReportDateTimeFormat() {
            return reportDateTimeFormat;
        },

        getComparator(dateField, order) {
            order = order || 1;
            return function (item1, item2) {
                const date1 = item1[dateField];
                const date2 = item2[dateField];

                if (!date1 || !date2) {
                    return 0;
                }

                return order * date1.localeCompare(date2);
            };
        },
    };
}

function formatDate(
    timestamp,
    shouldAppendTimezone,
    timezoneName,
    withoutConversion
) {
    clientTimezoneName = Session.getInstance().getClient().getTimezone();
    timezoneName =
        timezoneName || getTimezoneName(timestamp) || clientTimezoneName;
    timestamp = timestampCleanup(timestamp);
    timestamp = withoutConversion ? moment.utc(timestamp) : moment(timestamp);
    if (timezoneName && !withoutConversion) {
        timestamp = timestamp.tz(timezoneName);
    }
    return (
        timestamp.format(dateFormat) +
        (shouldAppendTimezone ? getFormattedTimezone(timezoneName) : "")
    );
}

function formatReportDate(timestamp) {
    return moment
        .utc(timestampCleanup(timestamp))
        .format(reportDateWithZeroTimeFormat);
}

function getTimezone(timestamp) {
    const timezone = timestamp.slice(-6);

    if (!timezone.match(/^(\+|\-)/)) {
        return 0;
    }

    return timezone;
}

function getTimezoneName(timestamp) {
    if (!_.isString(timestamp)) {
        return;
    }

    const timezoneName = timestamp.match(/(\[.+?\])|(\[.+?)$/);
    if (!timezoneName) {
        return;
    }

    return timezoneName[0].replace(/[\[\]]/g, "");
}

function getFormattedTimezone(timezoneName) {
    if (!timezoneName) {
        return "";
    }

    return moment.tz(timezoneName).format(" z");
}

function timestampCleanup(timestamp, isFullCleanup) {
    if (_.isString(timestamp)) {
        const index = timestamp.indexOf("[");
        if (index > -1) {
            timestamp = timestamp.substring(0, index);
        }
        const { length } = timestamp;
        if (timestamp[length - 1] === "Z") {
            timestamp = timestamp.slice(0, length - 1);
        }
        timestamp = timestamp.replace(/(\+|-)\d\d:\d\d/, "");
        if (isFullCleanup) {
            return timestamp.slice(0, timestamp.indexOf("T") + timeLength + 1);
        }
    }

    return timestamp;
}

function parseDates(startDate, endDate) {
    if (
        !endDate &&
        startDate &&
        (_.contains(startDate, "~") || _.contains(startDate, "/"))
    ) {
        let timestamps = startDate.split("/");
        if (
            timestamps.length > 2 ||
            (timestamps[1] && !timestamps[1].match(/^[0-9]/))
        ) {
            timestamps = startDate.split(/~|\]\//);
        }
        startDate = timestamps[0];
        endDate = timestamps[1];
    }

    return {
        startDate,
        endDate,
    };
}

export default DateTime();
