import { computed, toValue } from "vue";
import { useBuiltComponent } from "./useBuiltComponent";
export const useBuiltCampaignComponentVariable = (componentId, variableName) => {
    const { builtComponent, isLoading } = useBuiltComponent(componentId);
    const builtCampaignComponentVariable = computed(() => {
        var _a;
        const builtVariable = (_a = builtComponent.value) === null || _a === void 0 ? void 0 : _a.findVariable(toValue(variableName));
        return builtVariable;
    });
    return {
        builtCampaignComponentVariable,
        isLoading,
    };
};
