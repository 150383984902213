import Highcharts from "highcharts";
import _ from "underscore";
import $ from "jquery";
import ChartColors from "src/shared/charts/ChartColors";
import Numbers from "src/shared/Numbers";

function Column(labels, values, options) {
    options = options || {};
    const { dataLabelFormatter } = options;
    this.render = function (element) {
        $(element).addClass("column-chart-2");
        const chart = new Highcharts.Chart({
            chart: {
                type: "column",
                renderTo: element,
                backgroundColor: "transparent",
                marginTop: 35,
                marginBottom: 0,
                height: options.height,
            },
            title: {
                text: null,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            yAxis: {
                gridLineColor: "transparent",
                gridLineWidth: 0,
                plotLines: [
                    {
                        color: "lightgrey",
                        width: 1,
                        value: 0,
                    },
                ],
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
            },
            xAxis: {
                tickLength: 0,
                lineColor: "transparent",
                categories: labels,
                labels: {
                    enabled: false,
                },
            },
            plotOptions: {
                column: {
                    grouping: false,
                    pointWidth: 150,
                    dataLabels: {
                        enabled: false,
                    },
                },
                series: {
                    borderColor: ChartColors.LIGHT_GRAY,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    data: _.map(values, (value, i) => {
                        const item = {
                            y: value,
                        };
                        if (i === values.length - 1) {
                            item.color = ChartColors.BLUE;
                        } else {
                            item.color = ChartColors.LIGHTER_GRAY;
                        }
                        return item;
                    }),
                    dataLabels: {
                        y: -10,
                        useHtml: true,
                        enabled: true,
                        overflow: "allowed",
                        crop: false,
                        verticalAlign: "bottom",
                        formatter() {
                            return `<span class='column-chart-2__key'>${
                                this.key
                            }:</span> <span class='column-chart-2__value'>  ${Numbers.humanize(
                                this.y
                            )}</span>`;
                        },
                    },
                },
            ],
        });
    };
}

export default Column;
