export default {
    BLUE: "#1A6AE5",
    GREEN: "#1E9336",
    MEDIUM_GRAY: "#777777",
    YELLOW: "#EAC234",
    ORANGE: "#EC6B10",
    PINK: "#E00049",
    LIGHT_GRAY: "#B4B4B4",
    LIGHTER_GRAY: "#f7f7f7",
    PURPLE: "#4C4793",
    RED: "#CC0000",
    WHITE: "#EFEFEF",
    LIGHT_BLUE: "#6BA9D7",
    LIGHT_GREEN: "#7ABE88",
    LIGHT_YELLOW: "#F2DB8B",
    LIGHT_ORANGE: "#F4A678",
};
