import { computed } from "vue";
import { useBuiltCampaignComponentVariable } from "./useBuiltCampaignComponentVariable";
export const useBuiltComponentVariableValue = (componentId, variableName, dimension) => {
    return computed(() => {
        var _a;
        const { builtCampaignComponentVariable } = useBuiltCampaignComponentVariable(componentId.value, variableName.value);
        const value = (_a = builtCampaignComponentVariable.value) === null || _a === void 0 ? void 0 : _a.values[dimension.value];
        return value;
    });
};
