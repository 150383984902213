var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useArrayEvery, useArraySome, useAsyncState, useMemoize, } from "@vueuse/core";
import { computed, reactive, watch } from "vue";
export const useAsyncPrimer = useMemoize((promise, key) => {
    return useAsyncState(promise, undefined);
}, {
    getKey: (promise, key) => {
        return key;
    },
});
export const useCompositeAsyncPrimer = (...args) => {
    const isReady = useArrayEvery(args, (arg) => arg.isReady);
    const isLoading = useArraySome(args, (arg) => arg.isLoading);
    return {
        isReady,
        isLoading,
    };
};
export class AsyncPrimer {
    constructor(keyGenerator, primeInternal) {
        this.registry = reactive({});
        this.keyGenerator = keyGenerator;
        this.primeInternal = primeInternal;
    }
    getOrCreateLoadingState(key) {
        if (!this.registry[key]) {
            this.registry[key] = {
                isLoading: false,
                isReady: false,
            };
        }
        return this.registry[key];
    }
    prime(a) {
        watch(() => {
            const args = a();
            if (args === undefined) {
                return;
            }
            const key = this.keyGenerator(args);
            if (key === undefined) {
                return;
            }
            return {
                key,
                args,
            };
        }, (watched) => __awaiter(this, void 0, void 0, function* () {
            if (watched === undefined) {
                return;
            }
            const { key, args } = watched;
            const state = this.getOrCreateLoadingState(key);
            if (!state.isReady && !state.isLoading) {
                try {
                    state.isLoading = true;
                    yield this.primeInternal(args);
                    state.isReady = true;
                }
                finally {
                    state.isLoading = false;
                }
            }
        }), {
            immediate: true,
        });
        const isLoading = computed(() => {
            const args = a();
            if (args === undefined) {
                return false;
            }
            const key = this.keyGenerator(args);
            if (key === undefined) {
                return false;
            }
            return this.getOrCreateLoadingState(key).isLoading;
        });
        const isReady = computed(() => {
            const args = a();
            if (args === undefined) {
                return false;
            }
            const key = this.keyGenerator(args);
            if (key === undefined) {
                return false;
            }
            return this.getOrCreateLoadingState(key).isReady;
        });
        return {
            isLoading,
            isReady,
        };
    }
}
