import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import moment from "moment";
import dateTimeService from "src/shared/date-time-service";
import RewardModel from "src/account-rewards/RewardModel";

const RewardCollection = Backbone.Collection.extend({
    url: "api:///v2/rewards",
    model: RewardModel,
});

const RewardStateSummaryCollection = Backbone.Collection.extend({
    url: "api:///v2/rewards/state_summary",
});

const service = {
    getLastEarnedDate(rewardSupplierId) {
        const deferred = $.Deferred();
        const rewards = new RewardCollection();
        rewards.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
                limit: 1,
                state: "EARNED",
            },
            success() {
                if (rewards.isEmpty()) {
                    deferred.resolve(null);
                } else {
                    rewards
                        .first()
                        .getHistory()
                        .done((rewardStateResponses) => {
                            if (_.isEmpty(rewardStateResponses)) {
                                deferred.resolve(null);
                            } else {
                                deferred.resolve(
                                    _.first(rewardStateResponses).created_at
                                );
                            }
                        })
                        .fail(deferred.reject);
                }
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getLastFulfilledDate(rewardSupplierId) {
        const deferred = $.Deferred();
        const rewards = new RewardCollection();
        rewards.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
                limit: 1,
                state: "FULFILLED",
            },
            success() {
                if (rewards.isEmpty()) {
                    deferred.resolve(null);
                } else {
                    rewards
                        .first()
                        .getHistory()
                        .done((rewardStateResponses) => {
                            if (_.isEmpty(rewardStateResponses)) {
                                deferred.resolve(null);
                            } else {
                                deferred.resolve(
                                    _.first(rewardStateResponses).created_at
                                );
                            }
                        })
                        .fail(deferred.reject);
                }
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getNumberUnfulfilled(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
            },
            success() {
                const numberUnfulfilled = sumField(
                    stateSummary.toJSON(),
                    "failed"
                );
                deferred.resolve(numberUnfulfilled);
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getNumberFulfilled(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
            },
            success() {
                const numberFulfilled = sumField(
                    stateSummary.toJSON(),
                    "fulfilled"
                );
                deferred.resolve(numberFulfilled);
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getNumberDistributed(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
            },
            success() {
                deferred.resolve(sumDistributed(stateSummary));
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getNumberIssued(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
            },
            success() {
                const numberIssued = sumField(
                    stateSummary.toJSON(),
                    "fulfilled"
                );
                deferred.resolve(numberIssued);
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getAverageWeeklyDistribution(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
                period: "WEEK",
                period_count: 3,
            },
            success() {
                const distributed = sumDistributed(stateSummary);
                const average = distributed / stateSummary.length;
                deferred.resolve(average);
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },
    getAverageMonthlyDistribution(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        stateSummary.fetch({
            data: {
                reward_supplier_id: rewardSupplierId,
                period: "MONTH",
                period_count: 12,
            },
            success() {
                const distributed = sumDistributed(stateSummary);
                const average = distributed / stateSummary.length;
                deferred.resolve(average);
            },
            error() {
                deferred.reject();
            },
        });
        return deferred;
    },

    getMonthlyRewardUsageByType(rewardType, periodCount) {
        const deferred = $.Deferred();
        periodCount = periodCount || 3;
        const monthlyUsage = calculateResultTemplateForPastNMonths(periodCount);
        populateDefaults(monthlyUsage, "usage", 0);
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: {
                    success_only: true,
                    reward_type: rewardType,
                    period: "MONTH",
                    period_count: periodCount,
                },
            })
        )
            .done(() => {
                const summarized = stateSummary.map((monthSummary) => {
                    const usage =
                        monthSummary.get("fulfilled") +
                        monthSummary.get("sent") +
                        monthSummary.get("redeemed");
                    const month = dateTimeService.formatToMonth(
                        monthSummary.get("date_to")
                    );
                    return {
                        usage,
                        month,
                    };
                });
                _.each(summarized, (summary) => {
                    const usage = _.findWhere(monthlyUsage, {
                        month: summary.month,
                    });
                    if (usage) {
                        usage.usage = summary.usage;
                    }
                });
                deferred.resolve(monthlyUsage);
            })
            .fail(deferred.reject);
        return deferred;
    },

    getMonthlyRewardUsageBySupplier(rewardSupplierId, periodCount) {
        const deferred = $.Deferred();
        periodCount = periodCount || 3;
        const monthlyUsage = calculateResultTemplateForPastNMonths(periodCount);
        populateDefaults(monthlyUsage, "usage", 0);
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: {
                    success_only: true,
                    reward_supplier_id: rewardSupplierId,
                    period: "MONTH",
                    period_count: periodCount,
                },
            })
        )
            .done(() => {
                const summarized = stateSummary.map((monthSummary) => {
                    const usage =
                        monthSummary.get("fulfilled") +
                        monthSummary.get("sent") +
                        monthSummary.get("redeemed");
                    const month = dateTimeService.formatToMonth(
                        monthSummary.get("date_to")
                    );
                    return {
                        usage,
                        month,
                    };
                });
                _.each(summarized, (summary) => {
                    const usage = _.findWhere(monthlyUsage, {
                        month: summary.month,
                    });
                    if (usage) {
                        usage.usage = summary.usage;
                    }
                });
                deferred.resolve(monthlyUsage);
            })
            .fail(deferred.reject);
        return deferred;
    },

    getStateSummaryDaily(filter) {
        const deferred = $.Deferred();
        const periodCount = 7;
        const stateSummaryOutput =
            calculateResultTemplateForPastNDays(periodCount);
        populateStateSummaryOutputDefault(stateSummaryOutput);
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: _.extend({}, filter, {
                    period: "DAY",
                    period_count: periodCount,
                }),
            })
        )
            .done(() => {
                stateSummary.each((summary) => {
                    const day = dateTimeService.formatToDay(
                        summary.get("date_to")
                    );
                    const output = _.findWhere(stateSummaryOutput, {
                        day,
                    });
                    if (output) {
                        _.extend(output, summary.toJSON());
                    }
                });
                deferred.resolve(stateSummaryOutput);
            })
            .fail(deferred.reject);
        return deferred;
    },

    getStateSummaryWeekly(filter) {
        const deferred = $.Deferred();
        const periodCount = 4;
        const stateSummaryOutput =
            calculateResultTemplateForPastNWeeks(periodCount);
        populateStateSummaryOutputDefault(stateSummaryOutput);
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: _.extend({}, filter, {
                    period: "WEEK",
                    period_count: periodCount,
                }),
            })
        )
            .done(() => {
                stateSummary.each((summary) => {
                    const week = dateTimeService.formatToWeek(
                        summary.get("date_to")
                    );
                    const output = _.findWhere(stateSummaryOutput, {
                        week,
                    });
                    if (output) {
                        _.extend(output, summary.toJSON());
                    }
                });
                deferred.resolve(stateSummaryOutput);
            })
            .fail(deferred.reject);
        return deferred;
    },

    getStateSummaryMonthly(filter) {
        const deferred = $.Deferred();
        const periodCount = 12;
        const stateSummaryOutput =
            calculateResultTemplateForPastNMonths(periodCount);
        populateStateSummaryOutputDefault(stateSummaryOutput);
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: _.extend({}, filter, {
                    period: "MONTH",
                    period_count: periodCount,
                }),
            })
        )
            .done(() => {
                stateSummary.each((summary) => {
                    const month = dateTimeService.formatToMonth(
                        summary.get("date_to")
                    );
                    const output = _.findWhere(stateSummaryOutput, {
                        month,
                    });
                    if (output) {
                        _.extend(output, summary.toJSON());
                    }
                });
                deferred.resolve(stateSummaryOutput);
            })
            .fail(deferred.reject);
        return deferred;
    },

    getStateSummaryAllTime(rewardSupplierId) {
        const deferred = $.Deferred();
        const stateSummary = new RewardStateSummaryCollection();
        $.when(
            stateSummary.fetch({
                data: {
                    success_only: true,
                    period: "MONTH",
                    period_count: 12,
                    reward_supplier_id: rewardSupplierId,
                },
            })
        )
            .done(() => {
                deferred.resolve({
                    earned: sumField(stateSummary.toJSON(), "earned"),
                    failed: sumField(stateSummary.toJSON(), "failed"),
                    fulfilled: sumField(stateSummary.toJSON(), "fulfilled"),
                    redeemed: sumField(stateSummary.toJSON(), "redeemed"),
                    sent: sumField(stateSummary.toJSON(), "sent"),
                    canceled: sumField(stateSummary.toJSON(), "canceled"),
                    revoked: sumField(stateSummary.toJSON(), "revoked"),
                });
            })
            .fail(deferred.reject);
        return deferred;
    },
};

function populateStateSummaryOutputDefault(output) {
    populateDefaults(output, "earned", 0);
    populateDefaults(output, "failed", 0);
    populateDefaults(output, "fulfilled", 0);
    populateDefaults(output, "redeemed", 0);
    populateDefaults(output, "sent", 0);
    populateDefaults(output, "canceled", 0);
    populateDefaults(output, "revoked", 0);
}

function sumDistributed(stateSummary) {
    const numberFulfilled = sumField(stateSummary.toJSON(), "fulfilled");
    const numberSent = sumField(stateSummary.toJSON(), "sent");
    const numerRedeemed = sumField(stateSummary.toJSON(), "redeemed");
    const total = numberFulfilled + numberSent + numerRedeemed;
    return total;
}

function populateDefaults(list, fieldName, defaultValue) {
    _.each(list, (item) => {
        item[fieldName] = defaultValue;
    });
}

function calculateResultTemplateForPastNDays(n) {
    const currentDay = moment();
    const template = [];
    while (template.length < n) {
        template.unshift({
            day: currentDay.format("ddd"),
            displayName: currentDay.format("ddd"),
        });
        currentDay.subtract(1, "day");
    }
    return template;
}

function calculateResultTemplateForPastNWeeks(n) {
    const currentWeek = moment();
    const template = [];
    while (template.length < n) {
        template.unshift({
            week: currentWeek.format("w"),
            displayName: `Week of ${currentWeek.startOf("week").format("M/D")}`,
        });
        currentWeek.subtract(1, "week");
    }
    return template;
}

function calculateResultTemplateForPastNMonths(n) {
    const currentMonth = moment();
    const template = [];
    while (template.length < n) {
        template.unshift({
            month: currentMonth.format("MMM"),
            displayName: currentMonth.format("MMM"),
        });
        currentMonth.subtract(1, "month");
    }
    return template;
}

function getCurrentMonth() {
    return moment(Date.now()).month();
}

function sumField(list, field) {
    return _.reduce(list, (sum, item) => sum + item[field], 0);
}

export default service;
