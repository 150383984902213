import $ from "jquery";
import _ from "underscore";
import ColumnChart from "src/account-rewards/Column2";
import stateSummaryService from "src/account-rewards/reward-state-summary-service";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import TooltipView from "src/shared/tooltip/TooltipView";
import tooltipConfigurations from "src/shared/rewards/tooltip-configurations.json";
import "src/shared/rewards/monthly-usage-chart-template.html?raw";

const MONTHS_COUNT = 3;

function renderChart(usages, $root) {
    renderTooltip();
    let noData = true;
    _.each(usages, (usage) => {
        if (usage.usage) {
            noData = false;
        }
    });

    if (noData) {
        $root.html("<div class='no-data'></div>");
        return;
    }

    const height = $root.outerHeight();
    const el = $root[0];
    const labels = [];
    const values = [];
    _.each(usages, (usage) => {
        labels.push(usage.month);
        values.push(usage.usage);
    });
    const chart = new ColumnChart(labels, values, {
        height,
    });
    chart.render(el);
}

function totalCouponUsages(usages) {
    return _.reduce(usages, (sum, usage) => sum + usage.usage, 0);
}

function renderAverageUsage(usages, $el) {
    const totalCouponUsage = totalCouponUsages(usages);
    const averageUsage = parseInt(totalCouponUsage / MONTHS_COUNT);
    const couponsPostfix = averageUsage === 1 ? "" : "s";
    $el.html(`Average: ${averageUsage} coupon${couponsPostfix}/month`);
}

export default {
    renderTypeUsageInto(rewardSupplierType, $root) {
        NotificationFlashView.create($root);
        stateSummaryService
            .getMonthlyRewardUsageByType(rewardSupplierType)
            .done((usages) => {
                renderChart(usages, $root);
            });
    },
    renderSupplierUsageInto(rewardSupplierId, $root) {
        NotificationFlashView.create($root);
        stateSummaryService
            .getMonthlyRewardUsageBySupplier(rewardSupplierId)
            .done((usages) => {
                renderChart(usages, $root);
            });
    },
    monthlyAverageCouponUsage(rewardSupplier, $root, isSupplierType) {
        if (isSupplierType) {
            stateSummaryService
                .getMonthlyRewardUsageByType(rewardSupplier)
                .done((usages) => {
                    renderAverageUsage(usages, $root);
                });
        } else {
            stateSummaryService
                .getMonthlyRewardUsageBySupplier(rewardSupplier)
                .done((usages) => {
                    renderAverageUsage(usages, $root);
                });
        }
    },
};

function renderTooltip() {
    TooltipView.renderAll(tooltipConfigurations);
}
