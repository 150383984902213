import { computed } from "vue";
import { useComponents } from "./useComponents";
export const useComponentByTag = (tag) => {
    const { components, primer } = useComponents();
    const component = computed(() => {
        return components.value.find((component) => {
            return component.tags.includes(tag);
        });
    });
    return {
        primer,
        component,
    };
};
